// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    3;


// Colors
$brand-primary:         #00b6d4;
$brand-secondary:       #e153a7;
$brand-white:           #fff;
$brand-black:           #1b1b2b;

$text-color:            #36474f;
$body-bg:               $brand-primary;


// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;


// Fonts
$font-family-sans-serif:      "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
$headings-font-family:        $font-family-sans-serif;
$headings-font-weight:        700;
$font-size-base:              16px;

// Border
$border-radius-base:    10px;
