html {
  min-height: 100%;

  @media screen and (max-width: $screen-sm) {
    margin-top: 0 !important;
  }
}

#wpadminbar {
  position: fixed !important;
}

body {
  position: relative;
  font-weight: 400;
  background-image: url('../images/gsn-logo-bg.jpg');
  background-repeat: repeat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
}

// Selection
::-webkit-selection {
  color: $brand-white;
  background: lighten($brand-secondary, 5%);
}

::-moz-selection {
  color: $brand-white;
  background: lighten($brand-secondary, 5%);
}

::selection {
  color: $brand-white;
  background: lighten($brand-secondary, 5%);
}

a {
  text-decoration: none;
  transition: all .3s ease;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}
.btn {
  padding: 10px 15px;
}

.btn-primary {
  background: $brand-secondary;
  border: 1px solid $brand-secondary;

  &:hover,
  &:focus {
    background: lighten($brand-secondary,10%);
    border: 1px solid lighten($brand-secondary,10%);
  }
}

.btn-outline {
  background: transparent;
  color: $brand-white;
  border: 1px solid $brand-white;
  border-radius: 10px;
  &:hover,
  &:focus {
    background: $brand-white;
    color: $brand-secondary;
  }
}


// Sharer
#sharer {
  display: block;
  text-align: center;
  padding: 30px 0;

  .title {
    display: inline-block;
    color: #ddd;
    margin-right: 10px;
  }
  .buttons {
    display: inline-block;

    a {
      color: #ccc;
      transition: all .3s ease-in-out;
      &:hover {
        color: $brand-primary;
      }
    }
  }
}


// Loader
.loader {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background-color: $brand-primary;
  transition: 0.8s;
}

.no-js .loader {
	display: none;
}

.loader-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}



// Animate
.animate-box {
	.js & {
		opacity: 0;
	}
}

// Social icons
.social-icons {
  margin-bottom: 0;
  color: #fff;
  font-family: $headings-font-family;
  font-size: 15px;
  font-weight: 800;
  text-transform: uppercase;

  i {
    font-size: 24px;
  }

  a {
    color: #fff;
    transition: all .3s ease;

    &:hover,
    &:focus {
      color: $brand-primary;
      text-decoration: none;
    }
  }
}


// 404
#page-not-found {
  position: relative;
  background-size: cover;
  background-position: center;
  color: #fff;
  width: 100%;
  height: 100vh;

  &:after {
    position: absolute;
    content: '';
    z-index: 1;
    width: 100%;
    height: 100%;
    background: $brand-primary;
    opacity: 0.9;
  }

  .header {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    max-width: 600px;
    padding: 30px;
    text-align: center;
    transform: translate(-50%,-50%);
    background: rgba(#000,.7);
    z-index: 2;

    h1 {
      font-size:  120px;
      font-weight: 900;
      color: #fff;

      span {
        display: block;
        font-size: 36px;
        letter-spacing: 2px;
        text-transform: uppercase;
        font-weight: bold;
      }
    }

    .message {
      margin-bottom: 30px;
    }

  }
}


// no-gutters
.row {
    &.no-gutters {
    margin-right: 0;
    margin-left: 0;

     > [class^="col-"],
     > [class*=" col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

// rubberBand from animatecss
@keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, 0.95, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}
