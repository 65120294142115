#page {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;
	transition: all .5s ease;
	z-index: 1;

	main {
		position: relative;
		z-index: 1;
	}

	.page-header {
		border-bottom: 0 none;
		margin: 0 0 30px;

		h1 {
			float: none;
			display: inline-block;
			margin-bottom: 30px;
		}

		.header-img {
			margin-bottom:30px;
		}

		#sharer {
			float:right;
			font-size: 24px;
			a {
				color: $text-color;
				&:hover,
				&:focus {
					color: $brand-secondary;
				}
			}
		}
	}

  .masonry-column {

    &:nth-child(2n) {
      .tile {
        &:after {
          background:rgba(#e40303,.8);
        }

        a {
          &:hover,
          &:focus {
            color: #e40303;
          }
        }
      }
    }

    &:nth-child(3n) {
      .tile {
        &:after {
          background:rgba(#ff8c00,.8);
        }
        a {
          &:hover,
          &:focus {
            color: #ff8c00;
          }
        }
      }
    }

    &:nth-child(4n) {
      .tile {
        &:after {
          background:rgba(#ffc000,.8);
        }
        a {
          &:hover,
          &:focus {
            color: #ffc000;
          }
        }
      }
    }

    &:nth-child(5n) {
      .tile {
        &:after {
          background:rgba(#008026,.8);
        }
        a {
          &:hover,
          &:focus {
            color: #008026;
          }
        }
      }
    }

    &:nth-child(6n) {
      .tile {
        &:after {
          background:rgba(#004dff,.8);
        }
        a {
          &:hover,
          &:focus {
            color: #004dff;
          }
        }
      }
    }


    &:nth-child(7n) {
      .tile {
        &:after {
          background:rgba(#713380,.8);
        }
        a {
          &:hover,
          &:focus {
            color: #713380;
          }
        }
      }
    }
  }

	.tile {
		position: relative;
		margin-bottom: 30px;
		background: $brand-secondary;
		color: $brand-white;
		text-align: center;
		border-radius: 20px;
		box-shadow: 0px 15px 30px rgba(0,0,0,.12);
		background-image: url(../images/gsn-bg.jpg);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		transition: all .3s ease-out;

		&:hover {
			box-shadow: 0px 8px 15px rgba(0,0,0,.24);
		}

		&:after {
			content:'';
			border-radius: 20px;
			position: absolute;
			width:100%;
			height: 100%;
			top: 0;
			left: 0;
			background:rgba($brand-secondary,.8);
		}

		a {
      color: $brand-white;
      text-shadow: none;
			&:hover,
      &:focus {
				color: $brand-primary;
				text-decoration: none;
			}
		}

		.inner {
			position:relative;
			z-index:1;
      padding: 60px;
      text-shadow: 1px 1px 2px rgba(0,0,0,0.3);

			h2 {
				font-size: 30px;
				margin-bottom: 30px;

				@media(min-width: $screen-md) {
					font-size: 40px;
				}
      }

      .lead {
        font-size: 20px;
      }
		}
	}

	.offcanvas & {

		&:after {
			transition: all 2s ease;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 100;
			background: rgba(0,0,0,.7);
			content: "";
		}
	}

	aside {
		.side-nav {
			position: relative;;
			 background:$brand-white;
			 margin-bottom:30px;
			 border-radius:20px;
			 background-repeat: no-repeat;
			 background-size: cover;
			 background-position: center;
			 background-color: $brand-secondary;
			 box-shadow: 0px 30px 40px rgba(0,0,0,.12);

			 &:after {
				 content:'';
				 position: absolute;
				 width: 100%;
				 height:100%;
				 top: 0;
				 left: 0;
				 background: rgba($brand-secondary,0.8);
				 border-radius: 20px;
			 }

			 .inner {
				 position: relative;
				 padding: 30px;
				 color: $brand-white;
				 z-index: 5;

				 h3 {
					 color: $brand-white;
           margin-top: 0;
           font-size: 18px;

           @media(min-width: $screen-md) {
            font-size: 24px;
           }
				 }

				 a {
           display: block;
					 color: $brand-white;
					 font-weight: 900;
					 padding: 5px;
				 }

				 ul {
					 li {
						 display: block;
					 }
				 }
			 }
		}
	}
  .content {
		.inner {
	    background: $brand-white;
	    border-radius:20px;
	    padding: 30px;
			box-shadow: 0px 30px 40px rgba(0,0,0,.12);
		}
  }
}

#parallax-bg {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	overflow:hidden;
	z-index:0;

	.layer {
		position:absolute;
	}
	.capote-small {
		right: 10px;
		bottom: 150px;
	}
	.capote-small-2 {
		left: 50px;
		top: 200px;
	}
	.capote {
		 right: 10px;
		 bottom: 400px;
	}
	.capote-2 {
		 left: 200px;
		 top: 50px;
	}
	.capote-big {
		 right: -200px;
		 top: 300px;
	}
	.capote-big-2 {
		left: -150px;
		bottom: -10px;
	}
}

#gmap {
	height: 400px;
	.gmap {
		height: 400px;
	}
}
