.content-info {
  position: relative;
  padding: 60px 0;
  clear: both;
  text-align: center;
  color: $brand-white;
  font-weight: 900;
  font-size: 12px;
  text-transform: uppercase;
  z-index: 2;

  a {
    color: rgba($brand-white,0.5);
    &:hover,
    &:focus {
      color: $brand-white;
    }
  }

  #newsletter {
    margin-bottom: 30px;

    .alert {
      h4 {
        margin-bottom: 0;
        text-transform: capitalize;
      }
    }

    .form-control {
      border-radius: 10px 0 0 10px;
      height: 44px;
    }
  }
}
