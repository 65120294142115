#blog,
#search {
  .blog-header {
    position: relative;
    margin-bottom: 30px;

    .inner {
      position: relative;
  		background: $brand-secondary;
  		color: $brand-white;
  		text-align: center;
      padding: 30px;
  		border-radius: 20px;
  		box-shadow: 0px 15px 30px rgba(0,0,0,.12);
  		background-image: url(../images/gsn-bg.jpg);
  		background-size: cover;
  		background-repeat: no-repeat;
  		background-position: center;

      &:after {
        content:'';
        position: absolute;
        width: 100%;
        height:100%;
        border-radius: 20px;
        background:rgba($brand-secondary,0.8);
        top: 0;
        left: 0;
      }

      .blog-title {
        position: relative;
        z-index: 1;
      }
    }
  }

  .post {
    position: relative;
    margin-bottom: 30px;

    .inner {
      position:relative;
      z-index:1;
      background: $brand-white;
      text-align: center;
      border-radius: 20px;
      box-shadow: 0px 15px 30px rgba(0,0,0,.12);
      transition: all .5s ease-out;

      &:hover {
        box-shadow: 0px 8px 15px rgba(0,0,0,.24);
      }

      .entry-image {
        img {
          border-radius: 20px 20px 0 0;
        }
      }
      .entry-summary {
        padding: 30px;
        color: $text-color;
        .entry-title {
          margin: 0 0 15px;
        }

        time {
          display: block;
          color: $brand-secondary;
          font-weight: 700;
          margin-bottom: 15px;
        }

        #sharer {
          padding: 0;
          margin: 0;
        }
      }

      h2 {
        font-size: 36px;
        margin-bottom: 30px;

        @media(min-width: $screen-md) {
          font-size: 54px;
        }
      }
    }
  }
}

.single-post {
  #page {
    .inner {
       padding: 0;

       .post {

         .header-image {
           img {
             border-radius: 20px 20px 0 0;
           }
         }

        .post-content {
          padding: 30px;

          .page-header {
            text-align: center;
            h1 {
              display: block;
              float: none;
              color: $brand-primary;
              margin-bottom: 15px;
            }
            time {
              display: block;
              color: $brand-secondary;
              font-weight: 700;
            }
            #sharer {
              display: block;
              float: none;
              padding: 15px;
            }
          }
        }
       }
     }
  }
}
