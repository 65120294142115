// off canvas
#offcanvas {
	position: absolute;
	z-index: 101;
	width: 270px;
	background: $brand-secondary;
	top: 0;
	right: 0;
	top: 0;
	bottom: 0;
	padding: 100px 40px 40px 40px;
	overflow-y: auto;
  transform: translateX(270px);
  transition: all .5s ease;

	.offcanvas & {
      transform: translateX(0);
	}

	ul {
		padding: 0;
		margin: 0;
    position: relative;

		li {
			padding: 0;
			margin: 0;
			list-style: none;
      margin-bottom: 12px;

			a {
				color: $brand-white;
				font-weight: 900;
				font-size: 18px;
				&:hover,
				&:focus {
					color: rgba($brand-white,0.5);
					text-decoration: none;
				}
			}

			> ul {
				padding-left: 20px;
				display: none;
			}
			&.offcanvas-has-dropdown {
				> a {
					display: block;
					position: relative;
					&:after {
						position: absolute;
						right: 0px;
						font-family: "Ionicons";
						content: "\f123";
						font-size: 20px;
						color: rgba(255,255,255,.2);
                        transition: all .5s ease;
					}
				}
				&.active {
					a {
						&:after {
							transform: rotate(-180deg);
						}
					}
				}
			}
		}
	}
}
