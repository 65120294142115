// Nav
header.nav {
	position: relative;
	padding: 0;
	width: 100%;
	z-index: 99;
	text-align: left;

    .top {
      background: $brand-white;
      padding: 15px 0;
      color: $text-color;
      font-size: 24px;
    }

    .search-form-sidebar {
      input {
        height: 44px;
        border-radius: 10px 0 0 10px;
      }
    }

    .masthead {
      position: relative;
			display: inline-block;
			left: 50%;
			transform: translateX(-50%);


      @media(min-width: $screen-sm) {
        // top: -30px;
				// margin-bottom: -60px;
      }

      .brand {
        a {
          margin: 30px 0;
          padding: 0;
          display: inline-block;
          text-indent: -9999px;
          width: 200px;
          height: 90px;
          background-image: url(../images/gsn-logo-neg.svg);
          background-repeat: no-repeat;
          background-position: center;
          background-size: 200px 90px;
          font-size: 36px;
          font-weight: 700;
          padding-left: 0;

          @media(min-width:$screen-md) {
            margin: 60px 0;
            width: 300px;
            height: 135px;
            background-size: 300px 135px;
          }
        }
      }
    }

    .social-icons {
      li {
        margin-right: 5px;

        a {
          color: $text-color;
          &:hover,
          &:focus {
            color: $brand-secondary;
          }
        }
      }
    }

	.primary-nav {
      text-align: right;
//        margin-top: 30px;
	}
	ul {
		padding: 0;
		margin: 2px 0 0 0;

        @media (max-width: $screen-md) {
          display: inline-block !important;
        }

		li {
			padding: 0;
			margin: 0;
			list-style: none;
			display: inline-block;

			a {
                transition: all .5s ease;
                &:hover,
                &:focus {
                  background: transparent;
				}
			}
			&.menu-item-has-children {
				position: relative;
				.sub-menu {
					width: 200px;
					box-shadow: 0px 14px 33px -9px rgba(0,0,0,0.2);
					z-index: 1002;
					visibility: hidden;
					opacity: 0;
					position: absolute;
					top: 40px;
					left: 0;
					text-align: left;
					background: $brand-white;
					padding: 20px;
                    border-radius: 4px;

                    &.animated {
                      animation-duration: .5s;
                    }

					&:before {
						bottom: 100%;
						// left: 30%;
						left: 40px;
						border: solid transparent;
						content: " ";
						height: 0;
						width: 0;
						position: absolute;
						pointer-events: none;
						border-bottom-color: #fff;
						border-width: 8px;
						margin-left: -8px;
					}

					li {
						display: block;
						margin-bottom: 7px;
						&:last-child {
							margin-bottom: 0;
						}
						a {
							padding: 2px 0;
							display: block;
							color: lighten($brand-black, 60%);
							line-height: 1.2;
							text-transform: lowercase;
							font-size: 15px;
							&:hover {
								color: $brand-primary;
							}
						}
					}
				}
				&:hover, &:focus {
                  a {
                      color: $brand-primary;
                  }
                  .sub-menu {
                       visibility: visible;
                       opacity: 1;

                    & > ul {
                      transition: all 0 ease !important;
                    }

                  }
               }
			}

		}
	}
}

// Burger Menu
.nav-toggle {
  position: absolute;
  cursor: pointer;
  text-decoration: none;
  right: 30px;
  top: 20px;
  display: inline-block;
  margin: 0 auto;
  height: 44px;
  z-index: 103;
	color: $text-color;
  border-bottom: none!important;
	vertical-align: middle;
	transition: all .2s ease-out;

	span {
		display: none;
		vertical-align: middle;
		color: $text-color;
		font-size: 18px;
		padding: 8px;
		font-weight: 900;
    transition: all .2s ease-out;

    @media(min-width:$screen-md) {
      display: inline-block;
    }
	}

//  @media screen and (max-width: $screen-md) {
//  	display: block;
//  }

  &.active {
		color: $brand-white;
		i {
			&::before, &::after {
				background: $brand-white;
			}
    }
		span {
			color: $brand-white;
		}
  }
  &:hover, &:focus, &:active {
  	outline: none;
  	border-bottom: none!important;
  }
  i {
  	position: relative;
	  display: inline-block;
		vertical-align: middle;
	  width: 25px;
	  height: 3px;
	  color: $text-color;
	  text-transform: uppercase;
	  text-indent: -55px;
	  background: $text-color;
	  transition: all .2s ease-out;
		 &::before, &::after {
	  	content:'';
		  width: 25px;
		  height: 3px;
		  background: $text-color;
		  position: absolute;
		  left:0;
		  transition:all .2s ease-out;
	  }
  }

  i::before {
    top: -7px;
  }
  i::after {
    bottom: -7px;
  }

  &.active i {
      background: transparent;

      &::before {
        top:0;
        transform: rotateZ(45deg);
      }
      &::after {
        bottom:0;
        transform: rotateZ(-45deg);
      }
  }

}
